
import { Options, Vue } from "vue-class-component";
import TableBni from "./table.vue";

@Options({
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
    },
  },
  components: {
    TableBni
  },
})
export default class BniKurModal extends Vue {
  props: any = this.$props;
  closeDialog() {
    this.$emit("update:modelValue", false);
  }

  registerNow() {
    window.open("https://kreditkecil-program.bni.co.id/pengajuan-produk?m=AGy1F6z5HaLiNEmP36", "_blank", "", true);
  }
}
